import React, {useState} from 'react';
import './TickerComp.css'
import Ticker from 'framer-motion-ticker';
import {urlFor} from '../../client';

const TickerComp = ({data, second}) => {

	const [slow, setSlow] = useState(false);

	return (
				<div className="ticker" onMouseEnter={()=>setSlow(false)} onMouseLeave={()=>setSlow(true)}>
					<Ticker duration={70} direction={second ? 1 : -1} isPlaying={slow}  >

						{data?.map((item, i)=>(
								<div className="img_slider_container" key={i}> <img src={urlFor(item?.imgUrl)} alt={i} className="img"/> </div>
							))}

					</Ticker>
				</div>


	)
}

export default TickerComp