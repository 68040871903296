import React, {useState} from 'react';
import './About.css';

import about_logo from '../../Assets/about.png';
import shadow from '../../Assets/shadow.png';
import {motion} from 'framer-motion'

const About = () => {

	const [show, setShow] = useState(false)

	return (
		<section id="About" className="gallery_main">
			
			<div className="title">
				<p className="text head_title" style={{textAlign:'center'}}>WHO ARE WE <br/><span className="highlight" style={{fontWeight:'bold', fontSize:'40px'}}>ABOUT US</span></p>
			</div>

			<motion.div 
					className="container_carrousel about_main flex_app"
					style={{display:'flex', gap:"15px", maxWidth:'98%'}}
					whileInView={{y:[100, 0], opacity:[0,1]}}
					transition={{duration:0.5}}>
				<div className="img_container_about" onMouseEnter={()=>setShow(true)} onMouseLeave={()=>setShow(false)}>	
					<img src={about_logo} alt="about" className="about_mascot"/>
					<img src={shadow} alt="about" className={`about_mascot shadow ${show && 'shadow-hover'}`}/>
				</div>
				<div className="about_text_con">
					<p className="text about_text 	Reg">
						With 8 years in <span className="highlight">Graphic Design</span>, <span className="highlight">Digital Art</span> and <span className="highlight">Thumbnail Creation</span>.<br/><br/>
						Im driven to infuse my creativity into Esports, Youtube and entertainment projects.<br/><br/>
						My strength lies in crafting captivating visuals that capture the essence of these dynamic industries. Let's bring a fresh and impactful touch to your vision. 
					</p>
				</div>
			</motion.div>

			
		</section>
	)
}

export default About