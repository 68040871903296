import React,{useEffect, useState} from 'react';
import './Footer.css';

import {BsInstagram, BsTwitter, BsBehance} from 'react-icons/bs';
import {HiOutlineMail} from 'react-icons/hi';
import {AiOutlinePhone} from 'react-icons/ai';

import { motion } from "framer-motion";

import {client} from '../../client';
import {urlFor} from '../../client';


const Footer = () => {

	const [partners, setPartners] = useState([]);

	useEffect(() => {
			    const query = '*[_type == "partners"]';

			    client.fetch(query).then((data) => {
			      setPartners(data);
			    });
			  }, []);

	return (
		<div id="Footer" className="footer flex_app column">

			<motion.div 
				whileInView={{y:[100, 0], opacity:[0,1]}} 
				transition={{duration:0.5}}
				className="flex_app column">

				<div className="flex_app logos_container">
					
					{partners.map((partner, i)=>(
						<img src={urlFor(partner.imgUrl)} alt="goal" className="logos" key={i} onClick={()=>window.open(partner.link)}/>
						))}

				</div>
				
				
				<div className="bar"></div>
				<div className="flex_app copy">
					<div className="flex_app icons-container">
						<motion.div
						    className="squar flex_app"
						    whileHover={{ scale: 1.2, rotate: 90 }}
						    whileTap={{ scale: 0.8, rotate: -90, borderRadius: "100%" }}
						  >
							  <a href="https://www.instagram.com/okami.444/" target="_blank" rel="noreferrer" className="footer_icon_holder"><BsInstagram className="footer-icons"/></a>
						</motion.div>
						<motion.div
						    className="squar flex_app"
						    whileHover={{ scale: 1.2, rotate: 90 }}
						    whileTap={{ scale: 0.8, rotate: -90, borderRadius: "100%" }}
						  >
							  <a href="https://twitter.com/OKAMIAM" target="_blank" rel="noreferrer" className="footer_icon_holder"><BsTwitter className="footer-icons"/></a>
						</motion.div>
						<motion.div
						    className="squar flex_app"
						    whileHover={{ scale: 1.2, rotate: 90 }}
						    whileTap={{ scale: 0.8, rotate: -90, borderRadius: "100%" }}
						  >
							  <a href="https://www.behance.net/okami" target="_blank" rel="noreferrer" className="footer_icon_holder"><BsBehance className="footer-icons"/></a>
						</motion.div>
						<motion.div
						    className="squar flex_app"
						    whileHover={{ scale: 1.2, rotate: 90 }}
						    whileTap={{ scale: 0.8, rotate: -90, borderRadius: "100%" }}
						  >
							  <a href="mailto:okamidesign9@gmail.com" target="_blank" rel="noreferrer" className="footer_icon_holder"><HiOutlineMail className="footer-icons"/></a>
						</motion.div>
						<motion.div
						    className="squar flex_app"
						    whileHover={{ scale: 1.2, rotate: 90 }}
						    whileTap={{ scale: 0.8, rotate: -90, borderRadius: "100%" }}
						  >
							  <a  href="https://wa.link/wiu2at" target="_blank" rel="noreferrer" className="footer_icon_holder"><AiOutlinePhone className="footer-icons"/></a>
						</motion.div>
					
					</div>
					<div className="flex_app emails">

						<span className="dot"></span>
						<h5 className="text copyright Bold" >	&copy; 2023 OKAMI</h5>
						<span className="dot"></span>
					</div>

				</div>
			</motion.div>
		</div>
	)
}

export default Footer