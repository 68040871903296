import React from 'react';
import './TestiCard.css';
import {urlFor} from '../../client';

const TestiCard = ({data}) => {
	return (
		<div className="testicard_main flex_app onhover flex_app column">
			<p className="text Mid">
				{data.text}
			</p>

			<span className="bar"></span>
			<div className="flex_app">
				<img src={urlFor(data.imgUrl)} className="test_logo" alt="logo"/>
				<h4 className="text">{data.name}</h4>
			</div>

		</div>
	)
}

export default TestiCard