import React, {useEffect, useState} from 'react';
import './ShowCase.css';

import {urlFor} from '../../client';

const ShowCase = ({show, query, data}) => {

	const [work, setWork] = useState([])

	useEffect(() => {

		let filter = data?.filter((design)=>{
				return design?.tag.toLowerCase() === query.toLowerCase()
			})

		setWork(filter);

		return () => {
			setWork([])
		};
	}, [show])

	return (
		<div className={`showcase_main flex_app column ${show && 'show'}`}>

			<h3 className="text title_show Bold">{query}</h3>
			<div className="flex_app gal_container">
				
				{work.map((w, i)=>(
					<img src={urlFor(w.imgUrl)} alt="img" className="gal_img" key={i}/>
					))}

			</div>
		</div>
	)
}

export default ShowCase;