import React, {useEffect, useState} from 'react';
import './Thumbnails.css';

import {BsFillTriangleFill} from 'react-icons/bs';

import { motion } from "framer-motion";
import {client} from '../../client';

import TickerComp from '../../Components/TickerComp/TickerComp';


const Thumbnails = () => {

	const [work, setWork] = useState([]);
	const [fistSlide, setFirstSlide] = useState([]);
	const [secondSlide, setSecondSlide] = useState([])

	useEffect(() => {
			    const query = '*[_type == "thumbnails"]';

			    client.fetch(query).then((data) => {
			      setWork(data);

			      const middleIndex = Math.ceil(data.length / 2);
			      let firstHalf = data.splice(0, middleIndex);
			      let secondHalf = data.splice(-middleIndex);

			      setFirstSlide(firstHalf)
			      setSecondSlide(secondHalf)
			    });
			  }, []);

	return (
		<section id="Thumbnails" className="gallery_main">
			
			<div className="title">
				<p className="text head_title" style={{textAlign:'center'}}>OUR <br/><span className="highlight" style={{fontWeight:'bold', fontSize:'40px'}}>YOUTUBE THUMBNAILS</span></p>
				<BsFillTriangleFill className="arrow" style={{ stroke: "black", strokeWidth: "2"}}/>
			</div>

			<motion.div 
					className="container_carrousel"
					style={{display:'flex', flexDirection:'column', gap:"15px", maxWidth:'98%'}}
					whileInView={{y:[100, 0], opacity:[0,1]}}
					transition={{duration:0.5}}>

					{fistSlide.length > 0 && <TickerComp data={fistSlide}/>}
					{secondSlide.length > 0 && <TickerComp data={secondSlide} second={true}/>}
			</motion.div>

			
		</section>
	)
}

export default Thumbnails