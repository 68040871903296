import './App.css';

import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Slider from './Components/Slider/Slider';
import FloatingBar from './Components/FloatingBar/FloatingBar';

import Home from './Containers/Home/Home';
import Thumbnails from './Containers/Thumbnails/Thumbnails';
import Services from './Containers/Services/Services';
import About from './Containers/About/About';
import Testimonials from './Containers/Testimonials/Testimonials';

function App() {
  return (
    <div className="app flex_app column full">

      <Header/>

        <Home/>
        <Slider/>
        <About/>
        <FloatingBar/>
        <Thumbnails/>
        <Services/>
        <Testimonials/>
      <Footer/>

    </div>
  );
}

export default App;
