import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
  projectId: '05yzcbnp',
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token: 'skK47WWJ4WVFVnFsg3x6V3aAGQtxqs0bKeHLwTPMP8bE9NNRi6VylRLcd2Rtk5vT99xXkuXJbClRyCfAr5pHJK4OR8KK4MvmharCXbVuY7QlIdXAYgpzwJ5Bb2CMpOncOTjNy7wtpLFMMJ77rBirZykVciWPWoiHI0p8979YM1rz8PdMktNN',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);