import React, {useEffect, useState} from 'react';
import './Testimonials.css';

import {BsFillTriangleFill} from 'react-icons/bs';

import { motion } from "framer-motion";
import {client} from '../../client';

import TestiCard from '../../Components/TestiCard/TestiCard';

import Ticker from 'framer-motion-ticker';


const Testimonials = () => {

	const [testi, setTesti] = useState([]);


	useEffect(() => {
			    const query = '*[_type == "testimonials"]';

			    client.fetch(query).then((data) => {
			      setTesti(data);
			    });
			  }, []);

	return (
		<section id="Testimonials" className="gallery_main testi_main" style={{backgroundColor:'#1a142e', color:'white'}}>
			
			<div className="title" style={{height:'auto'}}>
				<p className="text head_title" style={{textAlign:'center'}}>OUR <br/><span style={{fontWeight:'bold', fontSize:'40px'}}>PARTNERS</span></p>
				<BsFillTriangleFill className="arrow" style={{ stroke: "black", strokeWidth: "2"}}/>
			</div>

			<motion.div 
					className="container_carrousel"
					style={{ gap:"10px", maxWidth:'80%'}}
					whileInView={{y:[100, 0], opacity:[0,1]}}
					transition={{duration:0.5}}>
				
				{testi.length > 0 &&

						<Ticker duration={100}>

							{testi?.map((testis, i)=>(
								<div key={i} className="img_container" style={{borderRadius:'unset', width:"98%"}}> <TestiCard data={testis}/> </div>
							))}

						</Ticker>

			  }

			</motion.div>

			
		</section>
	)
}

export default Testimonials