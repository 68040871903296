import React from 'react';
import './Card.css';

import {HiOutlineArrowsExpand} from 'react-icons/hi'

const Card = ({setShow, setQuery, title, text}) => {

	const action = () =>{
		setQuery(title);
		setShow(true)
	}

	return (
		<div className="card flex_app column" onClick={()=>action()}>
			<div className="shape"></div>
			<h3 className="service text Bold">{title}</h3>
			<p className='text adv Reg'>{text}</p>
			<div className="flex_app flow">
				<div className="shape2"></div>
				<HiOutlineArrowsExpand className="expand"/>
			</div>
		</div>
	)
}

export default Card