import React, {useState, useEffect} from 'react';
import './Services.css';

import {BsFillTriangleFill} from 'react-icons/bs';

import Card from '../../Components/Card/Card';
import ShowCase from '../../Components/ShowCase/ShowCase';

import { motion } from "framer-motion";
import {client} from '../../client';

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.2
    }
  }
};


const Services = () => {

	const [query, setQuery] = useState('');
	const [show, setShow] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
			    const query = '*[_type == "works"]';

			    client.fetch(query).then((data) => {
			      setData(data);
			    });
			  }, []);

	return (
		<section id="Services" className="gallery_main">

			<div className={`bg flex_app column ${show && 'appear'}`}>
				<h5 className="text quit animation" onClick={()=>setShow(false)}>+</h5>	
				<ShowCase show={show} query={query} data={data}/>
			</div>

			
			<div className="title">
				<p className="text head_title" style={{textAlign:'center'}}>OUR <br/><span className="highlight" style={{fontWeight:'bold', fontSize:'40px'}}>SERVICES</span></p>
				<BsFillTriangleFill className="arrow" style={{ stroke: "black", strokeWidth: "2"}}/>
			</div>

			<motion.ul className="container_carrousel wrap flex_app"
			    variants={container}
			    initial="hidden"
			    whileInView="visible"
			    style={{overflow:'hidden', marginLeft:'-40px'}}>
				<motion.li variants={item}>
					<Card setShow={setShow} setQuery={setQuery} title={'Artworks'} text={'Our skilled artists blend creativity and technology to craft visually stunning masterpieces. From breathtaking landscapes to intricate character designs, our digital paintings bring your imagination to life with vibrant colors and intricate details.'}/>
				</motion.li>

				<motion.li variants={item}>
					<Card setShow={setShow} setQuery={setQuery} title={'Sports'} text={"We specialize in creating impactful visuals that capture the essence of athleticism and competition. Whether it's designing energetic team logos, eye-catching sports event posters, or energetic merchandise, our sport-focused designs convey the excitement and passion of the game."}/>
				</motion.li>

				<motion.li variants={item}>
					<Card setShow={setShow} setQuery={setQuery} title={'Illustrations'} text={"Our Illustrations service tells stories through art, adding depth and charm to your projects. Whether it's for books, magazines, marketing materials, or personal projects, our illustrators infuse every stroke with emotion and creativity."}/>
				</motion.li>

			</motion.ul>

			
		</section>
	)
}

export default Services