import React, {useEffect, useState} from 'react';
import './Home.css';

import { motion } from "framer-motion";

import mascot from '../../Assets/mascot.png';

const getWindowSize = () => {
	  const {innerWidth} = window;
	  return innerWidth
	}


const Home = () => {
	const [value, setValue] = useState(0);
	const [repeat ,setRepeat] = useState(0);
	const [windowSize, setWindowSize] = useState(getWindowSize());

	function sleep(ms) {
	    return new Promise(resolve => setTimeout(resolve, ms));
	}

	useEffect(() => {
		const handleWindowResize = () =>{
			setWindowSize(getWindowSize());
		}
		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, [])


	useEffect(() => {
		(async () =>{
					if(windowSize >= 1100){
						setValue(20);
						
						await sleep(600);
						setValue(0); 
						
						await sleep(600);
						setRepeat(repeat +1); 
					}
				})();
	}, [repeat, windowSize])

	return (
		<section className="home flex_app">

			<motion.div 
				className="container flex_app"
				whileInView={{y:[100, 0], opacity:[0,1]}} 
				transition={{duration:0.5}}
				>
				

				<div className="intro flex_app column">
					<p className="text head">Hi, I am  </p>
					<b className="highlight" style={{fontSize:'3rem'}}>OKAMI</b> 
					<span>
						<p className="text big">
							A <b className="highlight">Graphic Designer</b>, <b className="highlight">Digital Artist</b> and <b className="highlight">Thumbnail Creator.</b>
						</p>
						<p className="text big">
							Shaping imagination Pixel by Pixel.
						</p>
					</span>
					<a className="button Bold" href="#Footer" style={{textDecoration:'none', fontSize:'1rem'}}>LET'S LEVEL UP YOUR GAME</a>
				</div>

				<div className="mascot_container flex_app column">
					<img alt="mascot" src={mascot} className="logo" style={{paddingBottom:`${value}px`}}/>
					<div className={`mascot_shadow_down ${windowSize <= 1100 && 'stop'}`}></div>
				</div>
			</motion.div>
		</section>
	)
}

export default Home