import React, {useState} from 'react'
import './Header.css';
import {BsInstagram, BsTwitter, BsBehance} from 'react-icons/bs';
import {MdAlternateEmail} from 'react-icons/md'

import {BiMenuAltRight} from 'react-icons/bi';
import logo from '../../Assets/logo.png';

const Header = () => {

	const [show, setShow] = useState(true);

	return (
		<header id="Home" className="full flex_app">
			<div className="header_main flex_app">
				<div className="flex_app pointer">
					<img src={logo} alt="logo" className="header_logo"/>
					<h2 className="text name animation Bold"> OKAMI </h2>
				</div>
				<div className="flex_app tabs" >
					<a href="#Home" className="text tab Bold noa">Home</a>
					<a href="#About" className="text tab Bold noa">About</a>
					<a href="#Thumbnails" className="text tab Bold noa">Thumbnails</a>
					<a href="#Services" className="text tab Bold noa">Services</a>
				</div>
				<div className="holder flex_app">
					<span className={`flex_app icon_holder ${show && 'icon_holder_show'}`}>
						<a href="https://www.instagram.com/okami.444/" target="_blank" rel="noreferrer" className="header_icon_holder"><BsInstagram className="header-icons"/></a>
						<a href="https://twitter.com/OKAMIAM" target="_blank" rel="noreferrer" className="header_icon_holder"><BsTwitter className="header-icons"/></a>
						<a href="https://www.behance.net/okami" target="_blank" rel="noreferrer" className="header_icon_holder"><BsBehance className="header-icons"/></a>
						<a href="mailto:okamidesign9@gmail.com" target="_blank" rel="noreferrer" className="header_icon_holder"><MdAlternateEmail className="header-icons"/></a>

					</span>
					<BiMenuAltRight className="menu animation" onClick={()=>setShow(!show)}/>
				</div>
			</div>
		</header>
	)
}

export default Header