import React from 'react';
import './Slider.css';

import Star from '../../Assets/star.png';

import Ticker from 'framer-motion-ticker';


const data =['Graphic Designer', 'Digital Artist', 'Thumbnail Designer','Graphic Designer', 'Digital Artist', 'Thumbnail Designer','Graphic Designer', 'Digital Artist', 'Thumbnail Designer','Digital Artist']


const Slider = () => {


	return (
		<section className="slider_main_x">

			<Ticker duration={40}>

						{data.map((item, i)=>(
						<div className="flex_app" key={i}>
							<img src={Star} alt="Star" className="star"/>
							<p className="Mid">{item}</p>
						</div>
						))}

			</Ticker>
		</section>
	)
}

export default Slider